import { FC, useRef } from 'react'
import styles from './BasicHero.module.scss'
import { BasicHeroProps } from './BasicHero.types'
import { Badge } from 'components/Badge'
import { Button } from 'components/Phantom/Button'
import { Img } from 'components/basic/Img'
import { Type } from 'components/Type'
import { VideoGif } from 'components/basic/VideoGif'

export const BasicHero: FC<BasicHeroProps> = (props) => {
	const { eyebrow, headline, subheadline, cta, media, centered, shade, leftAlign } = props

	const containerRef = useRef<HTMLDivElement>(null)

	return (
		<section
			className={styles.container}
			ref={containerRef}
			data-centered={!!centered}
			data-left-align={!!leftAlign}
		>
			<header>
				<Badge.Orange>{eyebrow}</Badge.Orange>
				<Type.Headline1>{headline}</Type.Headline1>
				<Type.Headchapter>{subheadline}</Type.Headchapter>
				<Button.White
					id={'hero-cta'}
					href={cta.href}
				>
					{cta.text}
				</Button.White>
			</header>
			<div
				className={styles.media_container_basic}
				data-shade={shade}
			>
				{media.type === 'image' && (
					<Img
						src={media.data.src}
						alt={media.data.alt}
						sources={[
							{
								src: media.data.mobileSrc,
								mediaQuery: '(max-width: 1023px)',
								dprHeight: media.data.dprHeight ?? 1500,
							},
							{
								src: media.data.src,
								mediaQuery: '(min-width: 1023px)',
								dprHeight: media.data.dprHeight ?? 1500,
							},
						]}
						doNotCompress={media.data.doNotCompress}
						className={media.className}
						objectFit={'cover'}
					/>
				)}
				{media.type === 'video' && (
					<VideoGif
						src={media.data.src}
						mobileSrc={media.data.mobileSrc}
						poster={media.data.poster}
						loop={media.data.loop}
						className={media.className}
					/>
				)}
			</div>
		</section>
	)
}

/**
 * @deprecated This is a temporary component for the Charles Leclerc launch, and should be removed after the launch.
 */
export const CLHero: FC<Partial<BasicHeroProps>> = (props) => {
	const { eyebrow, headline, subheadline, cta, media, centered, shade, leftAlign } = props

	const containerRef = useRef<HTMLDivElement>(null)

	return (
		<section
			className={styles.container}
			ref={containerRef}
			data-centered={!!centered}
			data-left-align={!!leftAlign}
		>
			<header className={styles.cl_hero_header}>
				<Img
					src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cl-racing-text-desktop.png'}
					alt={'Champions Sleep on Eight Sleep'}
					sources={[
						{
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cl-racing-text-mobile.png',
							mediaQuery: '(max-width: 1023px)',
						},
						{
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cl-racing-text-desktop.png',
							mediaQuery: '(min-width: 1023px)',
						},
					]}
					className={styles.cl_hero_text}
				/>
				<Type.Eyebrow className={styles.cl_hero_eyebrow}>Charles Leclerc, Racing Driver</Type.Eyebrow>
				<Button.White
					id={'hero-cta'}
					href={'/athletes'}
				>
					Learn more
				</Button.White>
			</header>
			<div className={styles.media_container_basic}>
				<Img
					src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cl-homepage-hero-2025.jpg'}
					alt={'Ferrari F1 Driver Charles Leclerc lying on a bed next to the Pod 4'}
					sources={[
						{
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cl-homepage-hero-2025-mobile.jpg',
							mediaQuery: '(max-width: 1023px)',
							dprHeight: 1500,
						},
						{
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cl-homepage-hero-2025.jpg',
							mediaQuery: '(min-width: 1023px)',
							dprHeight: 1500,
						},
					]}
					className={styles.cl_hero_image}
					objectFit={'cover'}
				/>
			</div>
		</section>
	)
}
