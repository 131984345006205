import { FC } from 'react'
import styles from './SplitFeature.module.scss'
import { SplitFeatureProps } from './SplitFeature.types'
import { Type } from 'components/Type'
import { Button } from 'components/Phantom/Button'
import { Img } from 'components/basic/Img'
import { KlarnaPinkBadge } from 'components/_const_assets/klarnaLogo'
import { AffirmBoxedLogo } from 'components/_const_assets/affirmLogo'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'

export const SplitFeature: FC<SplitFeatureProps> = (props) => {
	const { badge, header, subheader, cta, image, theme, financingProvider } = props

	const ButtonComponent = theme === 'dark' ? Button.White : Button.Dark

	return (
		<section
			className={styles.container}
			data-theme={theme}
		>
			<div className={styles.wrapper}>
				<header>
					<Type.Headline2 className={styles.header}>{header}</Type.Headline2>
					<Type.Body1 className={styles.subheader}>{subheader}</Type.Body1>
					<ButtonComponent
						id={'split-feature-cta'}
						href={cta.href}
						className={styles.cta}
					>
						{cta.text}
					</ButtonComponent>
				</header>
				<div className={styles.image_container}>
					<Img
						src={image.src}
						alt={image.alt}
						className={styles.image}
						objectFit={'cover'}
					/>
					<div className={styles.image_tag}>
						<FinancingProviderTag financingProvider={financingProvider} />
					</div>
				</div>
			</div>
		</section>
	)
}

const FinancingProviderTag: FC<{ financingProvider?: 'klarna' | 'affirm' }> = (props) => {
	const { financingProvider } = props
	if (financingProvider === 'klarna') {
		return (
			<Type.Headchapter className={styles.financing_tag}>
				Powered by <span className={'vh'}>Klarna</span>
				<KlarnaPinkBadge />
			</Type.Headchapter>
		)
	} else if (financingProvider === 'affirm') {
		return (
			<Type.Headchapter className={styles.financing_tag}>
				Powered by <span className={'vh'}>Affirm</span>
				<AffirmBoxedLogo />
			</Type.Headchapter>
		)
	}

	return null
}

export const SplitFeatureRiskFree = () => {
	const props: SplitFeatureProps = {
		badge: "Valentine's Day Sale",
		header: <>Try risk-free for 30&nbsp;nights</>,
		subheader: 'Experience the Pod at home. If it’s not the perfect fit, return it for a full refund.',
		cta: {
			text: 'Shop now',
			href: '/product/pod-cover',
		},
		image: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/risk-free-boxing-day.jpg',
			alt: 'A warmly lit room with the central focus being the Pod 4 Ultra and Pod hub.',
		},
		theme: 'white',
	}

	return <SplitFeature {...props} />
}

export const SplitFeatureFinancing = observer(() => {
	const { priceStore } = useRootStore()
	if (priceStore.currency !== 'USD') return null

	const props: SplitFeatureProps = {
		badge: "Valentine's Day Sale",
		header: `Financing options start from ${priceStore.pod4MonthlyPriceFormatted}/mo`,
		subheader: "Don’t miss out on our Valentine's Day Sale. Choose Affirm to pay in interest-free installments.",
		cta: {
			text: 'Shop now',
			href: '/product/pod-cover',
		},
		image: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/split-feature-2.png',
			alt: 'A warmly lit room with the central focus being the Pod 4 Ultra and Pod hub.',
		},
		financingProvider: 'affirm',
		theme: 'light',
	}

	return <SplitFeature {...props} />
})

export const SplitFeatureGeneric = observer(() => {
	const { priceStore } = useRootStore()

	const props: SplitFeatureProps = {
		badge: "Valentine's Day Sale",
		header: `Get ${priceStore.totalProductDiscountNumberFormatted} off with Valentine's Day Sale`,
		subheader: 'Shop now and transform your sleep experience.',
		cta: {
			text: 'Shop now',
			href: '/product/pod-cover',
		},
		image: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/split-feature-1.jpeg',
			alt: 'A coolly lit room with the central focus being the Pod 4 Ultra and Pod hub. There is a wooden nightstand with a present underneath.',
		},
		theme: 'dark',
	}

	return <SplitFeature {...props} />
})
