import { FC, Fragment, useEffect, useRef, useState } from 'react'
import styles from './NewStandard.module.scss'
import { NewStandardProps } from './types'
import { getDefaultBenefits } from './utils'
import { Icon, IconName } from 'components/Phantom/Icon'
import { VideoGif } from 'components/basic/VideoGif'
import { Button } from 'components/Phantom/Button'
import { FullScreenModal } from 'components/Phantom/_sections/ThisIsPod4Ultra'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'
import cx from 'classnames'
import { Type } from 'components/Type'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'

export const NewStandardConnected = observer(({ ctaUrl, afterIntelligentSystem, anchorHref, className, header, subheader, benefits, theme }: NewStandardProps) => {
	const { metricRegion } = useRootStore().settingsStore
	return (
		<NewStandard
			ctaUrl={ctaUrl}
			metric={metricRegion}
			afterIntelligentSystem={afterIntelligentSystem}
			anchorHref={anchorHref}
			className={className}
			header={header}
			subheader={subheader}
			benefits={benefits}
			theme={theme}
		/>
	)
})

export const NewStandard: FC<NewStandardProps> = (props) => {
	const { benefits = getDefaultBenefits(), metric = false, ctaUrl = '/product/pod-cover', header = 'The ultimate sleep solution', subheader, theme = 'white' } = props
	const [videoVisible, setVideoVisible] = useState(false)

	const videoSource = metric ? 'https://d115sb6i1ixllw.cloudfront.net/assets/new-standard-celsius.mp4' : 'https://d115sb6i1ixllw.cloudfront.net/assets/GE-2342%20Pod%204%20Ultra.mp4'

	return (
		<div
			className={cx(styles.container, props.className)}
			id={'new-standard'}
			data-theme={theme}
		>
			<header>
				<Type.Headline2 animateOnScroll>{header}</Type.Headline2>
				<Type.Body1 animateOnScroll>{subheader}</Type.Body1>
			</header>

			{benefits.length > 0 && (
				<ul className={styles.benefits}>
					{benefits.map((benefit, index) => (
						<Fragment key={index}>
							<Benefit
								icon={benefit.icon}
								text={benefit.text}
								index={index}
							/>
						</Fragment>
					))}
				</ul>
			)}

			<div className={styles.video_container}>
				<VideoGif src={videoSource} />

				<div className={styles.cta_container}>
					<Button.Empty
						onClick={() => setVideoVisible(true)}
						id={'new-standard-watch-video'}
						className={styles.video_button}
					>
						<Icon
							name={'PlayDark'}
							color={'white'}
						/>
						See how it works
					</Button.Empty>
				</div>
			</div>
			<FullScreenModal
				closeAction={() => {
					setVideoVisible(false)
				}}
				visible={videoVisible}
				aspectRatio={'16/9'}
			>
				<VideoPlayer
					metric={metric}
					forcePlaying={videoVisible}
				/>
			</FullScreenModal>
		</div>
	)
}

const Benefit: FC<{ icon: IconName; text: string; index: number }> = (props) => {
	const { icon, text, index } = props

	const ref = useRef<HTMLLIElement>(null)
	useAnimateInOnScroll(ref, { delay: index * 0.1 })

	return (
		<li
			key={index}
			className={styles.benefit}
			ref={ref}
		>
			<Icon
				name={icon}
				color={'black'}
			/>
			<Type.Body1>{text}</Type.Body1>
		</li>
	)
}

export const VideoPlayer = (props: { forcePlaying: boolean; metric: boolean }) => {
	const videoRef = useRef<HTMLVideoElement>(null)

	useEffect(() => {
		if (videoRef.current) {
			if (props.forcePlaying) {
				videoRef.current.play()
			} else {
				videoRef.current.pause()
			}
		}
	}, [props.forcePlaying])

	return (
		<div className={styles.videoContainer}>
			<video
				ref={videoRef}
				src={props.metric ? 'https://d115sb6i1ixllw.cloudfront.net/assets/pod_4_launch_metric_subtitles.mp4' : 'https://d115sb6i1ixllw.cloudfront.net/assets/Pod%204%20launch%20with%20subtitles.mp4'}
				preload={'none'}
				autoPlay={false}
				loop={false}
				controls
			/>
		</div>
	)
}
